<template>
  <updated-nav-bar/>
  <modal-newsletter/>

  <text-link v-if="updateExists" @click="refreshApp" :label="$t('messages.update_available_click_here_to_apply')" :icon="mdiUpdate" class="text-xs md:text-sm lg:text-base cursor-pointer text-blue-600 underline mx-5 my-5"/>

  <router-view/>
  <newsletter-bar/>
  <footer-bar/>

  <overlay v-show="isOverlayVisible" z-index="z-40" @overlay-click="overlayClick" />
  <snackbar/>
</template>

<script setup>
import { ref, computed, watch, onMounted, onBeforeUnmount } from 'vue'
import { useStore } from 'vuex'
import { useI18n } from 'vue-i18n'
import { useRoute } from 'vue-router'
import { mdiUpdate } from '@mdi/js'

import FingerprintJS from '@fingerprintjs/fingerprintjs'

import Overlay from '@/components/Overlay'
import Snackbar from '@/components/Snackbar'
import TextLink from '@/components/TextLink'
import FooterBar from '@/components/FooterBar'
import NewsletterBar from '@/components/NewsletterBar'
import UpdatedNavBar from '@/components/navigation/UpdatedNavBar'
import ModalNewsletter from '@/components/ModalNewsletter'

// Store and route
const store = useStore()
const route = useRoute()
const { t } = useI18n()

// State variables
const updateExists = ref(false)
const registration = ref(null)

const isAsideVisible = computed(() => store.state.display.isAsideVisible)
const isAsideHiddenLayout = computed(() => store.state.display.isAsideHiddenLayout)
const isOverlayVisible = computed(() => secondaryMenuItem.value || (isAsideHiddenLayout.value && isAsideVisible.value))

const secondaryMenuItem = computed({
  get: () => store.state.display.secondaryMenu,
  set: value => store.dispatch('display/secondaryMenuToggle', value)
})

// Functions
const secondaryMenuClose = () => {
  secondaryMenuItem.value = null
}

const menuClick = (event, item) => {
  if (item.darkModeToggle) {
    store.dispatch('display/darkMode')
  }
  if (item.menu) {
    store.dispatch('display/asideCompactToggle', false)
  }
  if (item.menuSecondary) {
    if (secondaryMenuItem.value && item.key === secondaryMenuItem.value.key) {
      secondaryMenuClose()
    } else {
      secondaryMenuItem.value = item
    }
  }
}

const refreshApp = () => {
  updateExists.value = false
  if (!registration.value || !registration.value.waiting) return
  registration.value.waiting.postMessage({ type: 'SKIP_WAITING' })
  window.location.reload()
}

const updateAvailable = (event) => {
  registration.value = event.detail
  updateExists.value = true
}

const menuClose = () => {
  store.dispatch('display/asideVisibilityToggle', false)
  secondaryMenuClose()
}

const overlayClick = () => {
  if (secondaryMenuItem.value) {
    secondaryMenuClose()
  } else if (isAsideHiddenLayout.value && isAsideVisible.value) {
    menuClose()
  }
}

// Lifecycle hooks
onMounted(async () => {
  document.addEventListener('swUpdated', updateAvailable)
  const fp = await FingerprintJS.load()
  const { visitorId } = await fp.get()
  store.commit('auth/setVisitorId', visitorId)
})

onBeforeUnmount(() => {
  document.removeEventListener('swUpdated', updateAvailable)
})

watch(route, (to, from) => {
  store.commit('auth/setRouteChange', {
    to: to.path,
    from: from.path
  })
  store.dispatch('auth/storeRouteChange')
  store.dispatch('auth/getUserInformation')
}, { deep: false })
</script>

import axios from 'axios'
import Repository from '@/repositories/RepositoryFactory'
import filters from '@/utils/filters'

const StatisticsRepository = Repository.get('statistics')

export default {
  namespaced: true,
  state: {
    /* Sample data (commonly used) */
    countries: [],
    clients: [],
    history: [],
    products: [],
    updatesStatus: false
  },
  mutations: {
    /* A fit-them-all commit */
    basic (state, payload) {
      state[payload.key] = payload.value
    }
  },
  actions: {
    async fetchGenericMulti ({ commit, dispatch }, { payload, displayOptions }) {
      const series = []
      const payloads = payload

      try {
        await Promise.all(
          Object.entries(payload)
            .filter(([key]) => key !== 'start_date' && key !== 'end_date' && key !== 'country_code') // filter out start_date and end_date
            .map(async ([displayName, p]) => {
              const multiPayload = { ...p, start_date: payload.start_date, end_date: payload.end_date }

              if (payload.country_code) {
                multiPayload.country_code = payload.country_code
              }
              const returnObject = await dispatch('fetchGeneric', { payload: multiPayload, displayOptions })
              const dict = returnObject.series
              series.push(Object.values(dict)[0])
              return dict
            })
        )
      } catch (error) {
        console.log(error)
      }

      // console.log(series)
      return { categories: null, series }
    },
    async fetchTenneTImbalancePredictor ({ commit }, { payload, displayOptions }) {
      let series = []

      try {
        // For now only allow one field
        const response = await StatisticsRepository.getTenneTImbalancePredictor(payload)
        const returnObject = filters.simpleParseFetch(payload, displayOptions, response)
        series = Object.values(returnObject.series)
      } catch (error) {
        console.log(error)
      }

      return { series }
    },
    async fetchTenneTImbalanceControlStates ({ commit }, { payload, displayOptions }) {
      let series = []

      try {
        // For now only allow one field
        const response = await StatisticsRepository.getTenneTImbalanceControlStates(payload)
        const returnObject = filters.parseTenneTImbalanceControlStates(payload, displayOptions, response)
        series = Object.values(returnObject.series)
      } catch (error) {
        console.log(error)
      }

      return { series }
    },
    async fetchTennetSettlementPricesHeatmap ({ commit }, { payload, displayOptions }) {
      let series = []
      let yAxisCategories = []

      try {
        // For now only allow one field
        const response = await StatisticsRepository.getTennetSettlementPricesHeatmap(payload)
        const returnObject = filters.parseTennetSettlementPricesHeatmap(payload, displayOptions, response)
        yAxisCategories = returnObject.yAxisCategories
        series = Object.values(returnObject.series)
      } catch (error) {
        console.log(error)
      }

      return { yAxisCategories, series }
    },
    async fetchTennetImbalanceRelativeChange ({ commit }, { payload, displayOptions }) {
      let series = []
      let yAxisCategories = []

      try {
        // For now only allow one field
        const response = await StatisticsRepository.getTennetImbalanceRelativeChange(payload)
        const returnObject = filters.parseTennetImbalanceRelativeChange(payload, displayOptions, response)
        yAxisCategories = returnObject.yAxisCategories
        series = Object.values(returnObject.series)
        // console.log(series)
      } catch (error) {
        console.log(error)
      }

      return { yAxisCategories, series }
    },
    async fetchTennetImbalancePricesEod ({ commit }, { payload, displayOptions }) {
      let series = []
      let yAxisCategories = []

      try {
        // For now only allow one field
        const response = await StatisticsRepository.getTennetImbalancePricesEod(payload)
        const returnObject = filters.parseTennetImbalancePricesEod(payload, displayOptions, response)
        yAxisCategories = returnObject.yAxisCategories
        series = Object.values(returnObject.series)
        // console.log(series)
      } catch (error) {
        console.log(error)
      }

      return { yAxisCategories, series }
    },
    async fetchSensorData ({ commit }, { payload, displayOptions }) {
      let series = []
      let categories = []
      let retDate = ''

      try {
        // For now only allow one field
        const response = await StatisticsRepository.getSensorDataSpecific(payload)
        const returnObject = filters.parseSensorData(payload, displayOptions, response)
        retDate = null
        categories = null
        series = returnObject.series
      } catch (error) {
        console.log(error)
      }

      return { categories, series }
    },
    async fetchENTSOGConsumption ({ commit }, { payload, displayOptions }) {
      let series = []
      let categories = []
      let retDate = ''

      try {
        // For now only allow one field
        const response = await StatisticsRepository.getENTSOGConsumption(payload)
        const returnObject = filters.parseENTSOGConsumption(payload, displayOptions, response)
        retDate = returnObject.lastUpdated
        categories = returnObject.categories
        series = Object.values(returnObject.series)
      } catch (error) {
        console.log(error)
      }

      return { categories, series }
    },
    async fetchENTSOEDayAheadMovingAverageForCountry ({ commit }, { payload, displayOptions }) {
      let series = []

      try {
        // For now only allow one field
        const response = await StatisticsRepository.getENTSOEDayAheadMovingAverageForCountry(payload)
        const returnObject = filters.parseENTSOEDayAheadMovingAverageForCountry(payload, displayOptions, response)
        series = Object.values(returnObject.series)
      } catch (error) {
        console.log(error)
      }

      return { series }
    },
    async fetchTennetSettlementPricesMovingAverage ({ commit }, { payload, displayOptions }) {
      let series = []

      try {
        // For now only allow one field
        const response = await StatisticsRepository.getTennetSettlementPricesMovingAverage()
        const returnObject = filters.parseTennetSettlementPricesMovingAverage(payload, displayOptions, response)
        series = Object.values(returnObject.series)
      } catch (error) {
        console.log(error)
      }

      return { series }
    },
    async getMultiGasPrices ({ commit }, { payload, displayOptions }) {
      let series = []

      try {
        // For now only allow one field
        const response = await StatisticsRepository.getMultiGasPrices(payload)
        const returnObject = filters.parseMultiGasPrices(payload, displayOptions, response)
        series = Object.values(returnObject.series)
      } catch (error) {
        console.log(error)
      }

      return { series }
    },
    async fetchGIE ({ commit }, { payload, displayOptions }) {
      let series = []
      let categories = []
      let retDate = ''

      payload.fields[0].country_code = payload.country_code
      // field.country_code = payload.country_code

      try {
        // For now only allow one field
        const response = await StatisticsRepository.getGIEForCountry(payload.fields[0])
        const returnObject = filters.parseGIE(payload, displayOptions, response)
        retDate = returnObject.lastUpdated
        categories = returnObject.categories
        series = Object.values(returnObject.series)
      } catch (error) {
        console.log(error)
      }

      return { categories, series }
    },
    async fetchICESettlements ({ commit }, { payload, displayOptions }) {
      let series = []
      let categories = []
      let retDate = ''

      try {
        const response = await StatisticsRepository.getICESettlements(payload)
        const returnObject = filters.parseICESettlementsData(payload, displayOptions, response)
        retDate = returnObject.lastUpdated
        categories = returnObject.categories
        series = Object.values(returnObject.series)
      } catch (error) {
        console.log(error)
      }

      return { categories, series }
    },
    async fetchICE ({ commit }, { payload, displayOptions }) {
      let series = []

      try {
        const response = await StatisticsRepository.getICE(payload)
        const dict = filters.parseICEData(payload, displayOptions, response)
        series = Object.values(dict)
      } catch (error) {
        console.log(error)
      }
      // console.log(series)
      return { categories: null, series }
    },
    async fetchICEDaily ({ commit }, { payload, displayOptions }) {
      let series = []

      try {
        const response = await StatisticsRepository.getICEDaily(payload)
        const dict = filters.parseICEData(payload, displayOptions, response)
        series = Object.values(dict)
        // console.log(series)
      } catch (error) {
        console.log(error)
      }
      // console.log(series)
      return { categories: null, series }
    },
    async fetchICELive ({ commit }, { payload, displayOptions }) {
      let series = []

      try {
        const response = await StatisticsRepository.getICELive()
        const dict = filters.parseICELiveData(payload, displayOptions, response)
        series = Object.values(dict)
      } catch (error) {
        console.log(error)
      }
      // console.log(series)
      return { categories: null, series }
    },
    async fetchActualPrices ({ commit }, { payload, displayOptions }) {
      let series = []

      try {
        const response = await StatisticsRepository.getActualPrices(payload)
        const dict = filters.simpleParseFetch(payload, displayOptions, response)
        series = Object.values(dict)
      } catch (error) {
        console.log(error)
      }

      return { categories: null, series }
    },
    async fetchImbalancePrediction ({ commit }, { payload, displayOptions }) {
      let series = []
      let data = [] 

      try {
        const response = await StatisticsRepository.getImbalancePrediction(payload)
        const returnObject = filters.parseImbalancePrediction(payload, displayOptions, response)
        // console.log(returnObject)
        series = Object.values(returnObject.series)
        data = returnObject.data
      } catch (error) {
        console.log(error)
      }

      return { categories: null, series, data }
    },
    async fetchGeneric ({ commit }, { payload, displayOptions }) {
      let series = []

      try {
        const response = await StatisticsRepository.getGeneric(payload)
        const dict = filters.simpleParseFetch(payload, displayOptions, response)
        series = Object.values(dict)
      } catch (error) {
        console.log(error)
      }

      return { categories: null, series }
    },
    async fetchGenericSpreadMulti ({ commit, dispatch }, { payload, displayOptions }) {
      const series = []

      try {
        await Promise.all(
          Object.entries(payload)
            .filter(([key]) => key !== 'start_date' && key !== 'end_date' && key !== 'country_code') // filter out start_date and end_date
            .map(async ([displayName, p]) => {
              const multiPayload = { ...p, start_date: payload.start_date, end_date: payload.end_date }

              if (payload.country_code) {
                multiPayload.country_code = payload.country_code
              }
              const returnObject = await dispatch('fetchGenericSpread', { payload: multiPayload, displayOptions })
              const dict = returnObject.series
              series.push(Object.values(dict)[0])
              return dict
            })
        )
      } catch (error) {
        console.log(error)
      }
      // console.log(series)
      return { categories: null, series }
    },
    async fetchGenericSpread ({ commit }, { payload, displayOptions }) {
      let series = []

      try {
        const response = await StatisticsRepository.getGenericSpread(payload)
        const dict = filters.simpleParseFetch(payload, displayOptions, response)
        series = Object.values(dict)
        // console.log(series)
      } catch (error) {
        console.log(error)
      }

      return { categories: null, series }
    },
    fetch ({ commit }, payload) {
      console.log(`fetching data-sources/${payload}.json`)
      axios
        .get(`data-sources/${payload}.json`)
        .then((r) => {
          if (r.data) {
            if (r.data.countries) {
              commit('basic', {
                key: payload,
                value: r.data.countries
              })
            }
            if (r.data.data) {
              commit('basic', {
                key: payload,
                value: r.data.data
              })
            }
            if (r.data.status) {
              commit('basic', {
                key: `${payload}Status`,
                value: r.data.status
              })
            }
          }
        })
        .catch(error => {
          alert(error.message)
        })
    }
  },
  getters: {
    euCountries (state) {
      return state.countries
    }
  }
}

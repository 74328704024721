<template>
  <div ref="target">
    <card-component footer v-if="displayCard" smallerPadding footerWithoutBorder headerWithoutBorder cardWithoutBorder>
      <!-- Top bar -->
      <div class="flex flex-col lg:flex-row justify-between bg-white dark:bg-gray-700 py-4 space-y-4 lg:space-y-0">
        <!-- Title Container -->
        <div class="flex flex-col lg:flex-col lg:space-y-2 ">
          <h2 class="font-bold text-lg">{{ title }}</h2>
        </div>
        <!-- Date range picker and buttons -->
        <div class="flex flex-col lg:flex-row items-start lg:items-center space-y-4 lg:space-y-0 lg:space-x-4">
          <!-- Additional Links or Buttons -->
          <div class="flex space-x-2">
            <!-- <text-link @click="downloadJPEG" :icon="mdiHeartOutline" :underline="false" small class="pr-5"/> -->
            <text-link @click="downloadJPEG" :icon="mdiImage" :underline="false" small class="pr-5"/>
            <text-link @click="downloadCSV" to="/" :icon="mdiContentSave" :underline="false" small class="pr-5"/>
          </div>
          <!-- Date Picker -->
          <VueDatePicker v-show="hideDatePicker !== true" v-model="dateRange" locale="nl" range :enable-time-picker="false" class="w-full lg:w-64" @update:model-value="changeDateRange"></VueDatePicker>
        </div>
      </div>

      <!-- Chart container -->
      <div class="bg-white dark:bg-gray-600 border min-h-24">
        <!-- Time range buttons -->
        <div class="flex justify-between items-center border p-2">
          <!-- <div v-show="!hideTimeIntervals && possibleTimeIntervals.length > 1" class="flex space-x-2 text-sm">
            <button v-for="interval in possibleTimeIntervals" :key="interval" class="text-blue-600 font-semibold hover:underline p-2" :class="{ 'bg-gray-100 rounded': fetchOptions.date_selection == interval.date_selection}" @click="changeDateSelection(interval.date_selection)">{{ interval.label }}</button>
          </div> -->
          <!-- <div v-if="isMobileScreen && !hideTimeIntervals" class="flex space-x-2 text-sm">
            Selected: {{ selectedTimeInterval }} -
            <control borderless type="list" :options="possibleTimeIntervals" v-model="selectedTimeInterval" @update:modelValue="changeTimeInterval($event)"/>
          </div>
          <div v-else-if="!hideTimeIntervals" class="flex space-x-2 text-sm">
            Selected: {{ selectedTimeInterval }} - {{ possibleTimeIntervals }}

            <button v-for="interval in possibleTimeIntervals" :key="interval" class="text-blue-600 font-semibold hover:underline p-2" :class="{ 'bg-gray-100 rounded': selectedTimeInterval.date_selection == interval.date_selection}" @click="changeTimeInterval(interval)">{{ interval.label }}</button>
          </div> -->

          <!-- Price range options -->
          <div class="flex space-x-2 text-sm series-dropdown" v-if="isMobileScreen && selectableSerieNames.length == 1">
            {{ selectableSerieNames[0].name}}
          </div>
          <div class="flex space-x-2 text-sm series-dropdown" v-else-if="isMobileScreen || selectableSerieNames.length > 5">
            <control small borderless type="list-multi" :options="toRaw(selectableSerieNames)" v-model="selectedSeries" @update:modelValue="changeActiveSerie($event)"/>
          </div>
          <div v-else class="flex space-x-2 text-sm">
            <span v-for="seriesName in selectableSerieNames" :key="seriesName.id" class="text-blue-600 font-semibold cursor-pointer hover:underline p-2" :class="{ 'bg-gray-100 rounded': seriesName.visible }" @click="changeActiveSerie(seriesName)">{{ seriesName.name }}</span>
          </div>
          <!--
          <div class="flex space-x-2 text-sm" v-else-if="selectableSerieNames.length > 4">
            <control type="list-multi" :options="toRaw(selectableSerieNames)" v-model="selectedSeries" @update:modelValue="changeActiveSeries($event)"/>
          </div> -->
        </div>

        <!-- Chart -->
        <highcharts v-if="series" class="chart py-2" :uuid="uuid" :options="localChartOptions" ref="chartRef"></highcharts>
      </div>

      <!-- Footer, contains country select, but this needs to be placed elsewhere -->
      <template #footer>
        <country-dropdown v-if="countrySelectOptions" @updateCountry="updateCountry" :countryOptions="countrySelectOptions" :selectedCountry="selectedCountry" />
      </template>
    </card-component>
  </div>
</template>

<script setup>
import { toRef, ref, onUnmounted, onMounted, watch, computed, defineProps, defineEmits, toRaw } from 'vue'
import { useStore } from 'vuex'
import { useI18n } from 'vue-i18n'
import { useElementVisibility } from '@vueuse/core'
import { v4 as uuidv4 } from 'uuid'
import { mdiImage, mdiContentSave, mdiHeartOutline } from '@mdi/js'
import VueDatePicker from '@vuepic/vue-datepicker'
import CardComponent from '@/components/CardComponent'
import CountryDropdown from '@/components/CountryDropdown'
import Control from '@/components/Control'
import Field from '@/components/Field'
import TextLink from '@/components/TextLink'
import { formatDateToYYYYMMDD, isNullOrUndefined } from '@/utils/helpers'
import { syncChartDimensions, addWatermark } from '@/utils/chart'
import {
  computedCardIcon,
  computedCountrySelectOptions,
  computedTitle,
  computedSelectableSeriesNames
} from '@/utils/chartComputed'

import { possibleTimeIntervals } from '@/utils/constants/chartConstants'
import { getChartOptions } from '@/utils/chartOptions'

// Props
const props = defineProps({
  column: {
    type: Object
  },
  updateKey: {
    type: Number,
    default: 0
  },
  parentCanForceUpdate: {
    type: Boolean,
    default: false
  }
})

const { t } = useI18n()
const store = useStore()
const isLoading = ref(false)

// Logic to hide or show the card
const displayCard = ref(true)

// Currently disabled, TODO make this a prop
const hideCard = (state, event) => {
  displayCard.value = false
}

// UUID for reference
const uuid = ref(uuidv4())
const series = ref([])

const selectableSerieNames = computedSelectableSeriesNames(series)
const selectedSeries = ref([])
const selectedTimeInterval = ref(possibleTimeIntervals[0])

const target = ref(null)
const chartRef = ref(null)
const categories = ref([])
const yAxisCategories = ref([])
const updateDate = ref(null)

const emit = defineEmits(['updateSeries', 'updateData'])

// Check if is visible
const targetIsVisible = useElementVisibility(target)
const targetWasVisible = ref(false)

// Options from column
const fetchOptions = ref(props.column.fetchOptions)
const fetchFunction = ref(props.column.fetchFunction)
const displayOptions = ref(props.column.displayOptions)

const isMobileScreen = computed(() => store.state.display.isXl === false && store.state.display.isLg === false)

const hideTimeIntervals = computed(() => 'hideTimeIntervals' in displayOptions.value ? displayOptions.value.hideTimeIntervals : false)
const hideDatePicker = computed(() => 'hideDatePicker' in displayOptions.value ? displayOptions.value.hideDatePicker : false)
const disabledTimeIntervals = 'disabledTimeIntervals' in displayOptions.value ? displayOptions.value.disabledTimeIntervals : []
// const possibleTimeIntervals = [{ label: '1m', date_selection: 'minute' }, { label: '1h', date_selection: 'hour' }, { label: '1D', date_selection: 'day' }, { label: '1W', date_selection: 'week' }, { label: '1M', date_selection: 'month' }, { label: '1Y', date_selection: 'year' }].filter(interval => !disabledTimeIntervals.includes(interval.date_selection))

const dateRange = ref([
  fetchOptions.value.start_date ? fetchOptions.value.start_date.split(' ')[0] : undefined,
  fetchOptions.value.end_date ? fetchOptions.value.end_date.split(' ')[0] : undefined
])

const localChartOptions = getChartOptions(uuid, displayOptions, fetchOptions, series, categories, yAxisCategories)

const disableLazyLoading = computed(() => {
  return ('disableLazyLoading' in displayOptions.value) ? displayOptions.value.disableLazyLoading : false
})

const showLastValue = computed(() => {
  return ('showLastValue' in displayOptions.value) ? displayOptions.value.showLastValue : false
})

const refreshTime = computed(() => {
  return ('refreshTime' in displayOptions.value) ? displayOptions.value.refreshTime : 60000
})

const countrySelectOptions = computedCountrySelectOptions(fetchOptions, displayOptions)
const selectedCountry = ref(countrySelectOptions.value && countrySelectOptions.value.length > 0 ? countrySelectOptions.value[0] : null)
const title = computedTitle(displayOptions, fetchOptions, selectedCountry)

const isAuthenticated = computed(() => store.state.auth.isAuthenticated)
const isSubscribed = computed(() => store.state.auth.isSubscribed)

// Download functions
const downloadCSV = () => {
  if (!isAuthenticated.value) {
    store.dispatch('display/pushMessage', t('messages.account_required'))
    return false
  }

  chartRef.value.chart.downloadCSV()
}
const downloadJPEG = () => {
  // if (!isAuthenticated.value) {
  //   store.dispatch('display/pushMessage', t('messages.account_required'))
  //   return false
  // }

  chartRef.value.chart.exportChart({
    type: 'image/png',
    filename: 'chart-image',
    showTable: true,
    chartOptions: {
      backgroundColor: '#FFFFFF',
      chart: {
        events: {
          load: function () {
            this.container.classList.add('export')
          }
        }
      }
    }
  })
}

// Function to fetch the results
const fetchData = async () => {
  if (isLoading.value) return
  isLoading.value = true

  // if not visible, or lazy loading is disabled and has been updated but not less then one minutes ago
  if (targetIsVisible.value === true || disableLazyLoading.value === true || (updateDate.value !== null && (new Date() - updateDate.value) < refreshTime.value)) {
    try {
      const response = await store.dispatch(fetchFunction.value, { payload: fetchOptions.value, displayOptions: displayOptions.value })
      let parsedSeries = response.series
      if (response.categories) categories.value = response.categories

      // Emit the data to the parent
      console.log(response)
      if (response.data) {
        emit('updateData', response.data)
      }

      // Determine visibility of the card and series
      if (parsedSeries === null || parsedSeries === undefined || parsedSeries.length === 0) {
        // hideCard()
      } else {
        parsedSeries = parsedSeries.sort((a, b) => a.name.localeCompare(b.name))
        parsedSeries.forEach(item => { item.visible = true })
      }
      series.value = parsedSeries
      emit('updateSeries', parsedSeries)
    } catch (error) {
      console.log(error)
    }
  }

  isLoading.value = false
}

const updateCountry = (value) => {
  console.log('updateCountry', value)
  if ('ice_index' in fetchOptions.value && 'countrySelect' in displayOptions.value) {
    fetchOptions.value = {
      ...fetchOptions.value,
      ice_index: value.value
    }
  } else {
    fetchOptions.value = {
      ...fetchOptions.value,
      country_code: value.label
    }
  }

  selectedCountry.value = value
  fetchData()
}

// Change the date selection
const changeTimeInterval = (timeInterval) => {
  // if ('date_selection' in value) {
  selectedTimeInterval.value = timeInterval
  // fetchOptions.value = {
  //   ...fetchOptions.value,
  //   date_selection: value.date_selection
  // }
  // }
}

// Change the date range for the chart
const changeDateRange = (value) => {
  // Update the date selection
  dateRange.value = value
  // Assign the new start and end date
  fetchOptions.value = {
    ...fetchOptions.value,
    start_date: formatDateToYYYYMMDD(dateRange.value[0]) + ' 00:00:00',
    end_date: formatDateToYYYYMMDD(dateRange.value[1]) + ' 00:00:00'
  }
}

// Change the Active Serie
const changeActiveSerie = (serie) => {
  series.value.forEach(item => {
    if (item.name === serie.name) item.visible = !item.visible
  })
}

// Check if the charts dimensions should be synced
watch(chartRef, (updatedRef) => {
  if (
    isNullOrUndefined(updatedRef) ||
    isNullOrUndefined(displayOptions.value.syncId) ||
    isNullOrUndefined(displayOptions.value.syncWhat)) {
    return
  }
  if (('syncWhat' in displayOptions.value) && ('syncId' in displayOptions.value)) {
    watch(() => store.state.display.syncedCharts, () => {
      syncChartDimensions(updatedRef, displayOptions, store, uuid)
    }, { deep: true })
  }
})

// If the target is now visible and it wasn't visible before, fetch the data
watch(targetIsVisible, (targetIsNowVisible) => {
  if (targetIsNowVisible && !targetWasVisible.value) {
    targetWasVisible.value = true
    fetchData()
  }
})

watch(selectableSerieNames, () => {
  // set selectedSeries to visible series
  selectedSeries.value = selectableSerieNames.value.filter(serie => serie.visible)
})

watch(fetchOptions, () => {
  console.log('fetchOptions changed')
  fetchData()
})

const updateKeyRef = toRef(props, 'updateKey')

watch(updateKeyRef, (first, second) => {
  if (props.parentCanForceUpdate) {
    console.log('updateKey changed', first, second)
    fetchData()
  }  
})

let intervalId = null
onMounted(() => {
  // Set up the interval to fetch data periodically
  intervalId = setInterval(() => {
    fetchData()
  }, refreshTime.value)

  // Make sure to clear the interval when the component is unmounted
  onUnmounted(() => {
    if (intervalId) {
      clearInterval(intervalId)
    }
  })
})

</script>

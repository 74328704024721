<template>
  <hero-bar/>

  <main-section>    
    <div class="grid grid-cols-1 xl:grid-cols-2 xl:gap-6 mb-6">
      <div class="xl:flex xl:flex-col xl:col-span-1 mb-4 xl:mb-0">      
        <card-component v-if="startDate" :icon="mdiBriefcaseVariant" title="Wijzig start" class="mr-4" footer>          
          <p v-if="autoUpdate">Data herlaadt elke minuut. Stel eigen begindatum in om autoupdate te stoppen.</p>
          <p v-else>Autoupdate gestopt. Verwijder de datum om autoupdate te hervatten.</p>
          <VueDatePicker v-model="startDate" locale="nl" class="w-full lg:w-64 mt-5" @update:model-value="changeStartDate"></VueDatePicker>
        </card-component>
        <dynamic-card v-if="startDate" key="control-state" :column="renderedColumn" ref="cardRefs" :updateKey="updateKey" @updateData="handleUpdatedData" parentCanForceUpdate/>
      </div>      
      <div class="xl:flex xl:flex-col xl:col-span-1 mb-6 xl:mb-0">
        <card-component :icon="mdiBriefcaseVariant" title="Tabel" class="mr-4 " has-table>
          <table class="w-full min-w-max table-auto border-collapse custom-table">
            <thead>
              <tr class="bg-gray-100 text-left text-sm">
                <th class="p-2">Datum</th>
                <th class="p-2">ISP</th>
                <th class="p-2">AFRR In</th>
                <th class="p-2">AFRR Out</th>
                <th class="p-2">Min Price</th>
                <th class="p-2">Mid Price</th>
                <th class="p-2">Max Price</th>
                <th class="p-2">Regeltoestand</th>
                <th class="p-2">Kans</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in data" :key="item.key" class="border-b">
                <td class="p-2">{{ item.key_as_string.slice(0,16) }}</td>
                <td class="p-2">{{ item.sequence }}</td>
                <td class="p-2">{{ item.power_afrr_in }}</td>
                <td class="p-2">{{ item.power_afrr_out }}</td>
                <td class="p-2">{{ fixedNumber(item.min_price) }}</td>
                <td class="p-2">{{ fixedNumber(item.mid_price) }}</td>
                <td class="p-2">{{ fixedNumber(item.max_price) }}</td>
                <td class="p-2">{{ item.regulation_state }}</td>
                <td class="p-2">{{ item.probability }}</td>
              </tr>
            </tbody>
          </table>
        </card-component>
      </div>
    </div>
  </main-section>
</template>

<script setup>
import { ref, reactive, computed, watch, onMounted, onUnmounted } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'
import { useStore } from 'vuex'
import {
  mdiTable,
  mdiBriefcaseVariant,
  mdiShipWheel
} from '@mdi/js'

import DynamicCard from '@/components/DynamicCard'
import column from '@/dashboards/single/predictionChart.json'
import VueDatePicker from '@vuepic/vue-datepicker'
import HeroBar from '@/components/HeroBar'
import MainSection from '@/components/MainSection'
import CardComponent from '@/components/CardComponent'
import filters from '@/utils/filters'
import Notification from '@/components/Notification'

const { t } = useI18n()
const store = useStore()
const router = useRouter()
const route = useRoute()
const autoUpdate = ref(true)

const updateKey = ref(0)

const renderedColumn = computed(() => {
  const res = filters.renderColumn(column)
  res.updateKey = updateKey.value
  return res
})

// const startDate = ref(renderedColumn.value.fetchOptions.start_date)
const startDate = ref(null)
const errorMessage = ref('')
const data = ref([])

const handleUpdatedData = (value) => {
  data.value = value
}

const fixedNumber = (value) => {
  if (value === null || value === undefined || isNaN(value) || value === '') {
    return ''
  }
  return value.toFixed(2)
}

onMounted(() => {
  store.dispatch('auth/getUserInformation')
})

const formatDate = (date) => {
  const pad = (num, size = 2) => String(num).padStart(size, '0') // Helper function for padding
  const year = date.getFullYear()
  const month = pad(date.getMonth() + 1) // Month is 0-based
  const day = pad(date.getDate())
  const hours = pad(date.getHours())
  const minutes = pad(date.getMinutes())
  const seconds = '00'
  const milliseconds = '000'

  // Calculate timezone offset
  const timezoneOffset = date.getTimezoneOffset() // In minutes
  const absOffset = Math.abs(timezoneOffset)
  const offsetHours = Math.floor(absOffset / 60)
  const offsetMinutes = absOffset % 60
  const offsetSign = timezoneOffset <= 0 ? '+' : '-'

  // Format timezone offset
  const formattedOffset = `${offsetSign}${pad(offsetHours)}:${pad(offsetMinutes)}`

  // Combine into final formatted string
  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}.${milliseconds}${formattedOffset}`
}

const changeStartDate = (mewStartDate) => {
  if (mewStartDate === null) {
    autoUpdate.value = true
    return
  }
  const endDate = new Date()
  endDate.setTime(mewStartDate.getTime() + (60 * 60 * 1000))

  column.fetchOptions.start_date = formatDate(mewStartDate)
  column.fetchOptions.end_date = formatDate(endDate)
  autoUpdate.value = false
  updateKey.value++
  data.value = []
  // console.log(startDate, endDate, updateKey.value)
}

const updateMinute = () => {
  if (autoUpdate.value === false) {
    return
  }

  const endDate = new Date()
  const newStartDate = new Date()

  newStartDate.setTime(endDate.getTime() - (60 * 60 * 1000))

  startDate.value = newStartDate
  column.fetchOptions.start_date = formatDate(newStartDate)
  column.fetchOptions.end_date = formatDate(endDate)
  updateKey.value++
  data.value = []
  // console.log(startDate, endDate, updateKey.value)
}

let intervalId = null
onMounted(() => {
  updateMinute()
  // Set up the interval to fetch data periodically
  intervalId = setInterval(() => {
    console.log('every interval')
    updateMinute()
  }, 60000)

  // Make sure to clear the interval when the component is unmounted
  onUnmounted(() => {
    if (intervalId) {
      clearInterval(intervalId)
    }
  })
})

</script>

<template>
  <multi-gas-price-world-map v-if="'chartComponent' in column && column.chartComponent === 'MultiGasPriceWorldMap'" :column="column"/>
  <latest-news-table v-else-if="'chartComponent' in column && column.chartComponent === 'LatestNewsTable'" :column="column" has-table/>
  <updated-base-chart v-else :column="column" :updateKey="updateKey" @updateData="handleUpdatedData" :parentCanForceUpdate="parentCanForceUpdate"/>
</template>

<script setup>
import LatestNewsTable from './charts/LatestNewsTable'
import UpdatedBaseChart from './charts/UpdatedBaseChart'
import MultiGasPriceWorldMap from './charts/MultiGasPriceWorldMap'

import { defineProps, defineEmits } from 'vue'

const props = defineProps({
  column: {
    type: Object
  },
  updateKey: {
    type: Number,
    default: 0
  },
  parentCanForceUpdate: {
    type: Boolean,
    default: false
  }
})

const emit = defineEmits(['updateData'])

const handleUpdatedData = (data) => {
  emit('updateData', data)
}
</script>
